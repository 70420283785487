<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    text: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let xAxisData = val.xdata;
        let seriesData1 = []
        let seriesData2 = []
        if (val.series.length > 0){
          seriesData1 = val.series[0].data;
          seriesData2 = val.series[1].data;
        }

        let option = {
          color: ["#3A7EEF", "#F4BB4E"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          legend: {
            show: true,
          },
          grid: {
            top: "12%",
            left: "5%",
            right: "5%",
            bottom: "10%",
            // containLabel: true
          },
          xAxis: [
            {
              type: "category",
              data: xAxisData,
              axisPointer: {
                type: "shadow",
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#C3C3C3",
                },
              },
              axisLabel: {
                color: "#5a5a5a",
                fontSize: 13,
              },
              axisTick: {
                show: false,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "累计当量轴次",
              nameTextStyle: {
                color: "#5a5a5a",
                fontSize: 14,
              },
              // min: 0,
              // max: 3000,
              // interval: 50,
              axisLabel: {
                formatter: "{value}",
                color: "#5a5a5a",
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#C3C3C3",
                },
              },
              splitLine: {
                show: false,
              },
            },
            {
              type: "value",
              name: "累计车辆总数（辆）",
              nameTextStyle: {
                color: "#5a5a5a",
                fontSize: 14,
              },
              // min: 0,
              // max: 11000,
              // interval: 50,
              axisLabel: {
                formatter: "{value}",
                color: "#5a5a5a",
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#C3C3C3",
                },
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "累计当量轴次",
              type: "bar",
              barWidth:20,
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: seriesData1,
            },
            {
              name: "累计车辆总数",
              type: "bar",
              barWidth:20,
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: seriesData2,
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
<style scoped>
  .charts{
    width: 100%;
    height: calc(100% - 30px);
  }
</style>
