<template>
  <div ref="dom" class="charts chart-pie"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartPie",
    props: {
      value: Array,
      text: String,
      subtext: String,
    },
    data() {
      return {
        dom: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        this.$nextTick(() => {
          let option = {
            legend: {
              show: true,
              type:'scroll',
              orient:'vertical',
              right:0
            },
            tooltip:{
              show:true
            },
            series: [
              {
                name: val[0].name,
                type: 'pie',
                // selectedMode: 'single',
                radius: ['60%', '80%'],
                label: {
                  position: 'inner',
                  formatter:'{d}%'
                },
                center:['30%','50%'],
                labelLine: {
                  show: false
                },
                data: val[0].data
              },
              {
                name: val[1].name,
                type: 'pie',
                // selectedMode: 'single',
                radius: ['20%', '50%'],
                center:['30%','50%'],
                label: {
                  position: 'inner',
                  formatter:'{d}%'
                },
                labelLine: {
                  show: false
                },
                data: val[1].data
              }
            ],
          };
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
        });
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: calc(100% - 30px);
  }
</style>
