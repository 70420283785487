<template>
  <div class="flow-com manage-scroll">
    <div class="flow-two">
      <div class="common-module relative f-t-o">
        <div class="module-title flex justify-between items-center">
          <div class="flex items-center">
            <img
                src="../../../../../../assets/images/admin/single/icon/icon_quxian.png"
                alt=""
            />
            <span class="title-text ml-3">车流量统计</span>
          </div>
        </div>
        <div class="search-wrapper">
          <c-search
              v-model="form"
              labelWidth="10px"
              :searchItems="searchItems"
              @export="handleExport"
              @search="handleSearch"
          >
          </c-search>
        </div>
        <div class="module-content" v-loading="loading">
          <chart-two style="height: 100%" :value="chartData"></chart-two>
        </div>
      </div>
      <div class="f-t-t">
        <div class="common-module relative f-t-flex">
          <div class="module-title flex justify-between items-center">
            <div class="flex items-center">
              <img
                  src="../../../../../../assets/images/admin/single/icon/icon_quxian.png"
                  alt=""
              />
              <span class="title-text ml-3">车流量日统计图</span>
            </div>
          </div>
          <div class="search-wrapper">
            <c-search
                v-model="formDay"
                labelWidth="10px"
                :searchItems="searchItemDays"
                @search="handleSearchDay"
            >
            </c-search>
          </div>
          <div class="module-content" v-loading="chartDay.loading">
            <chart-five style="height: 100%" :value="chartDay"></chart-five>
          </div>
        </div>
        <div class="common-module relative f-t-flex">
          <div class="module-title flex justify-between items-center">
            <div class="flex items-center">
              <img
                  src="../../../../../../assets/images/admin/single/icon/icon_quxian.png"
                  alt=""
              />
              <span class="title-text ml-3">车流量月统计图</span>
            </div>
          </div>
          <div class="search-wrapper">
            <c-search
                v-model="formMonth"
                labelWidth="10px"
                :searchItems="searchItemMonths"
                @search="handleSearchMonth"
            >
            </c-search>
          </div>
          <div class="module-content" v-loading="chartMonth.loading">
            <chart-five style="height: 100%" :value="chartMonth"></chart-five>
          </div>
        </div>
      </div>
      <div class="common-module relative f-t-year">
        <div class="module-title flex justify-between items-center">
          <div class="flex items-center">
            <img
                src="../../../../../../assets/images/admin/single/icon/icon_quxian.png"
                alt=""
            />
            <span class="title-text ml-3">车流量年统计图</span>
          </div>
        </div>
        <div class="search-wrapper">
          <c-search
              v-model="formYear"
              labelWidth="10px"
              :searchItems="searchItemYears"
              @search="handleSearchYear"
          >
          </c-search>
        </div>
        <div class="module-content" v-loading="chartYear.loading">
          <chart-five style="height: 100%" :value="chartYear"></chart-five>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import chartTwo from "../charts/chart02.vue";
  import chartFive from "../charts/chart05.vue"
  import common from '../../../../../../assets/js/common'
  export default {
    name: "two",
    components: {
      chartTwo,
      chartFive
    },
    data() {
      return {
        loading:true,
        searchItems:[
          {
            label: "",
            span: 8,
            placeholder: '小时',
            model: "span",
            type:'select',
            options:[{
              value:0,
              label:'天'
            },{
              value:1,
              label:'小时'
            }],
          },
          {
            label: "",
            span: 16,
            placeholder: '',
            model: "date",
            type:'daterange',
          },
        ],
        form: {
          span: 1,
          date: [],
        },
        searchItemDays:[
          {
            label: "",
            span: 24,
            placeholder: '',
            model: "date",
            type:'date',
          },
        ],
        formDay:{
          date:''
        },
        searchItemMonths:[
          {
            label: "",
            span: 24,
            placeholder: '',
            model: "date",
            type:'month',
          },
        ],
        formMonth:{
          date:''
        },
        searchItemYears:[
          {
            label: "",
            span: 24,
            placeholder: '',
            model: "date",
            type:'year',
          },
        ],
        formYear:{
          date:''
        },
        chartData: {
          series:[],
          xdata:[]
        },
        chartDay:{
          loading:false,
          series:[
            {name:'车流量',data:[]}
          ],
          xdata:[1,2,3,4,5,6]
        },
        chartMonth:{
          loading:false,
          series:[
            {name:'车流量',data:[]}
          ],
          xdata:[]
        },
        chartYear:{
          loading:false,
          series:[
            {name:'车流量',data:[]}
          ],
          xdata:[]
        }
      };
    },
    created() {
      let date = this.$moment(new Date()).format('YYYY-MM-DD')
      date = date.substr(0,10)
      this.form.date = [date,date]
      this.getData()
      this.formDay.date = date;
      this.formMonth.date = date.substr(0,7);
      this.formYear.date = date.substr(0,4);
      this.handleSearchDay();
      this.handleSearchMonth();
      this.handleSearchYear();
    },
    methods:{
      getData(){
        this.loading = true
        let params = {
          span:this.form.span,
          startTime:this.form.date[0] + ' 00:00:00',
          endTime:this.form.date[1] + ' 23:59:59'
        }
        this.$http.get("/car/carFlowEcharts", {params:params}).then(res=>{
          if(res.success){
            if (res.data){
              this.chartData = res.data
            }
          } else {
            this.$message.error(res.msg)
          }
          this.loading = false
        })
      },
      handleSearch(){
        this.getData()
      },
      handleExport(){
        let params = {
          span:this.form.span,
          startTime:this.form.date[0] + ' 00:00:00',
          endTime:this.form.date[1] + ' 23:59:59'
        }
        this.$http.get('/car/exportCarFlow', {
          contentType:"application/x-www-form-urlencoded",
          responseType: "blob",
          params: params
        }).then(res => {
          common.blobDownLoad(res)
        })
      },
      handleSearchDay(){
        this.chartDay.loading = true;
        console.log(this.formDay.date);
        let params = {
          day: this.formDay.date
        };
        this.$http.get('/car/statistics/day', {params: params}).then(res => {
          if(res.success){
            if (res.data){
              this.chartDay.series[0].data = res.data.yaxis;
              this.chartDay.xdata = res.data.xaxis;
            }
          } else {
            this.$message.error(res.msg)
          }
          this.chartDay.loading = false;
        });
      },
      handleSearchMonth(){
        this.chartMonth.loading = true;
        let params = {
          month: this.formMonth.date
        };
        this.$http.get('/car/statistics/month', {params: params}).then(res => {
          if(res.success){
            if (res.data){
              this.chartMonth.series[0].data = res.data.yaxis;
              this.chartMonth.xdata = res.data.xaxis;
            }
          } else {
            this.$message.error(res.msg)
          }
          this.chartMonth.loading = false;
        });
      },
      handleSearchYear(){
        this.chartYear.loading = true;
        let params = {
          year: this.formYear.date
        };
        this.$http.get('/car/statistics/year', {params: params}).then(res => {
          if(res.success){
            if (res.data){
              this.chartYear.series[0].data = res.data.yaxis;
              this.chartYear.xdata = res.data.xaxis;
            }
          } else {
            this.$message.error(res.msg)
          }
          this.chartYear.loading = false;
        });
      },
    }
  }
</script>

<style scoped>
  .flow-com {
    width: 100%;
    max-height: calc(100% - 40px);
    overflow-y: auto;
    /*background: #fff;*/
  }
  .flow-two{
    width: 100%;
    height: auto;
  }
  .f-t-o{
    width: 100%;
    height: 400px;
    background: #fff;
    margin-bottom: 15px;
  }
  .f-t-t{
    width: 100%;
    height: 400px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
  .f-t-flex{
    width: 49.5%;
    height: 100%;
    background: #fff;
  }
  .f-t-year{
    width: 100%;
    height: 400px;
    background: #fff;
  }
</style>