<template>
  <div class="flow-main">
    <div class="nav-list">
      <div :class="navActive === index ? 'nav-con' : 'nav-con nav-con-active'" v-for="(item,index) in navList" :key="index" @click="navClick(item,index)">
        <span>{{item.name}}</span>
      </div>
    </div>
    <module-one v-if="navActive === 0"></module-one>
    <module-two v-if="navActive === 1"></module-two>
    <module-three v-if="navActive === 2"></module-three>
    <module-four v-if="navActive === 3"></module-four>
  </div>
</template>

<script>
  import moduleOne from "./components/one";
  import moduleTwo from "./components/two";
  import moduleThree from "./components/three";
  import moduleFour from "./components/four";
  export default {
    name: "index",
    components:{moduleOne,moduleTwo,moduleThree,moduleFour},
    data(){
      return{
        navList:[
          {value:0,name:'实时流量监测'},
          {value:1,name:'车流量统计'},
          {value:2,name:'累计当量轴次'},
          {value:3,name:'车辆速度、类型统计'}
        ],
        navActive:0,
      }
    },
    methods:{
      navClick(data,i){
        this.navActive = i
      },
    }
  }
</script>

<style scoped>
.flow-main{
  width: 100%;
  height: 100%;
}
.nav-list{
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: flex;
}
.nav-con{
  width: 160px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-left: 1px solid #1A67D0;
  border-right: 1px solid #1A67D0;
  border-top: 1px solid #1A67D0;
  color: #1A67D0;
  font-weight: bold;
}
.nav-con:hover{
  cursor: pointer;
}
.nav-con i{
  color: #1A67D0;
  margin-right: 5px;
  font-weight: bold;
  font-size: 20px;
}
.nav-con-active{
  color: #fff;
  background: #1A67D0;
  border-bottom: 1px solid #1A67D0;
}
.nav-con-active i{
  color: #fff;
}
</style>