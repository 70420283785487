export default function Configs() {
  const tableColumns = [
    {
      label: "检测时间",
      prop: "check_time",
      align: "center",
      show: true
    },
    {
      label: "车道",
      prop: "lane_num",
      align: "center",
      show: true
    },
    {
      label: "车牌",
      prop: "car_num",
      align: "center",
      show: true
    },
    {
      label: "车牌颜色",
      prop: "car_color",
      align: "center",
      show: true
    },
    {
      label: "车重(kg)",
      prop: "gross_weight",
      align: "center",
      show: true
    },
    {
      label: "车速(km/h)",
      prop: "speed",
      align: "center",
      show: true
    }
  ];
  return {
    tableColumns
  };
}
