<template>
  <div class="flow-com manage-scroll">
    <div class="flow-three">
      <div class="common-module relative f-th-o">
        <div class="module-title flex justify-between items-center">
          <div class="flex items-center">
            <img
                src="../../../../../../assets/images/admin/single/icon/icon_tongji.png"
                alt=""
            />
            <span class="title-text ml-3">累计当量轴次统计</span>
          </div>
        </div>
        <div class="search-wrapper">
          <c-search
              v-model="form"
              labelWidth="10px"
              :searchItems="searchItems"
              @export="handleExport"
              @search="handleSearch"
          >
          </c-search>
        </div>
        <div class="module-content" v-loading="loading">
          <chart-one style="height: 100%" :value="chartData"></chart-one>
        </div>
      </div>
      <div class="flow-three-table">
        <div class="table-wrapper">
          <c-table
              :data="pageData"
              :tableColumns="tableColumns"
              :stripe="true"
          >
          </c-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Configs from "../config/three";
  import chartOne from "../charts/chart01.vue";
  import common from "../../../../../../assets/js/common";
  export default {
    name: "three",
    components: {
      chartOne,
    },
    data() {
      let { tableColumns } = Configs(this);
      return {
        tableColumns,
        loading:true,
        searchItems: [
          {
            label: "",
            span: 24,
            placeholder: "",
            model: "date",
            type: "daterange",
          },
        ],
        form: {
          date: [],
        },
        chartData: {
          series:[],
          xdata:[]
        },
        pageData: [],
      };
    },
    created() {
      let date = this.$moment(new Date()).format('YYYY-MM-DD')
      date = date.substr(0,10)
      this.form.date = [date,date]
      this.getData()
    },
    methods:{
      getData(){
        this.loading = true
        let params = {
          startTime:this.form.date[0] + ' 00:00:00',
          endTime:this.form.date[1] + ' 23:59:59'
        }
        this.$http.get("/car/axisEcharts",{params:params}).then(res=>{
          if(res.success){
            if (res.data){
              this.chartData = res.data.chart
              this.pageData = res.data.list
            }
          } else {
            this.$message.error(res.msg)
          }
          this.loading = false
        })
      },
      handleSearch(){
        this.getData()
      },
      handleExport(){
        let params = {
          span:this.form.span,
          startTime:this.form.date[0] + ' 00:00:00',
          endTime:this.form.date[1] + ' 23:59:59'
        }
        this.$http.get('/car/exportAxisAndCar', {
          contentType:"application/x-www-form-urlencoded",
          responseType: "blob",
          params: params
        }).then(res => {
          common.blobDownLoad(res)
        })
      },
    }
  }
</script>

<style scoped>
  .flow-com {
    width: 100%;
    max-height: calc(100% - 40px);
    overflow-y: auto;
  }
  .flow-three{
    width: 100%;
    height: auto;
  }
  .f-th-o{
    width: 100%;
    height: 400px;
    margin-bottom: 15px;
    background: #fff;
  }
  .flow-three-table{
    width: 100%;
    height: auto;
    background: #fff;
    padding: 20px;
  }
  /deep/ .el-table--scrollable-y .el-table__body-wrapper{
    height: auto !important;
  }
</style>