export default function Configs() {
  const tableColumns = [
    {
      label: "车道",
      prop: "laneNum",
      align: "center",
      show: true
    },
    {
      label: "时间跨度",
      prop: "time",
      align: "center",
      show: true
    },
    {
      label: "累计车辆总数",
      prop: "carTotal",
      align: "center",
      show: true
    },
    {
      label: "累计当量轴次",
      prop: "axisTotal",
      align: "center",
      show: true
    }
  ];
  return {
    tableColumns
  };
}
