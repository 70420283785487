<template>
  <div class="flow-com manage-scroll">
    <div class="common-module relative flow-four">
      <div class="module-title flex justify-between items-center">
        <div class="flex items-center">
          <img
              src="../../../../../../assets/images/admin/single/icon/icon_quxian.png"
              alt=""
          />
          <span class="title-text ml-3">车速统计</span>
        </div>
      </div>
      <div class="search-wrapper">
        <c-search
            v-model="form"
            labelWidth="10px"
            :searchItems="searchItems"
            @export="handleExport"
            @search="handleSearch"
        >
        </c-search>
      </div>
      <div class="flow-four-con" v-loading="loading">
        <div class="flow-four-left">
          <chart-three style="height: 100%" :value="chartData"></chart-three>
        </div>
        <div class="flow-four-right">
          <chart-six style="height: 100%" :value="chartDataPie"></chart-six>
        </div>
      </div>
    </div>

    <div class="common-module relative flow-four">
      <div class="module-title flex justify-between items-center">
        <div class="flex items-center">
          <img
              src="../../../../../../assets/images/admin/single/icon/icon_quxian.png"
              alt=""
          />
          <span class="title-text ml-3">车辆类型统计</span>
        </div>
      </div>
      <div class="search-wrapper">
        <c-search
            v-model="typeForm"
            labelWidth="10px"
            :searchItems="searchTypeItems"
            @export="handleTypeExport"
            @search="handleTypeSearch"
        >
        </c-search>
      </div>
      <div class="flow-four-con" v-loading="loading">
        <div class="flow-four-left">
          <chart-three style="height: 100%" :value="chartTypeData"></chart-three>
        </div>
        <div class="flow-four-right">
          <chart-six style="height: 100%" :value="chartTypeDataPie"></chart-six>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import common from "../../../../../../assets/js/common";
  import chartThree from "../charts/chart03.vue";
  import chartSix from "../charts/chart06.vue"
  export default {
    name: "four",
    components: {
      chartThree,
      chartSix
    },
    data(){
      return{
        loading:true,
        searchItems:[
          {
            label: "",
            span: 8,
            placeholder: '小时',
            model: "span",
            type:'select',
            options:[{
              value:0,
              label:'天'
            },{
              value:1,
              label:'小时'
            }],
          },
          {
            label: "",
            span: 16,
            placeholder: '',
            model: "date",
            type:'daterange',
          },
        ],
        form: {
          span: 1,
          date: [],
        },
        chartData: {
          series:[],
          xdata:[]
        },
        chartDataPie:[
          {
            name: '速度',
            data:[
              { value: 2, name: '0-40km/h' },
              { value: 2, name: '40-60km/h' },
              { value: 2, name: '60-80km/h' },
              { value: 2, name: '80-120km/h' },
              { value: 2, name: '120km/h' }
            ]
          },
          {
            name: '类型',
            data:[
              { value: 1, name: '上行' },
              { value: 1, name: '下行' },
              // { value: 1, name: '上行' },
              // { value: 1, name: '下行' },
              // { value: 1, name: '上行' },
              // { value: 1, name: '下行' },
              // { value: 1, name: '上行' },
              // { value: 1, name: '下行' },
              // { value: 1, name: '上行' },
              // { value: 1, name: '下行' },
            ]
          }
        ],
        searchTypeItems:[
          {
            label: "",
            span: 8,
            placeholder: '小时',
            model: "span",
            type:'select',
            options:[{
              value:0,
              label:'天'
            },{
              value:1,
              label:'小时'
            }],
          },
          {
            label: "",
            span: 16,
            placeholder: '',
            model: "date",
            type:'daterange',
          },
        ],
        typeForm: {
          span: 1,
          date: [],
        },
        chartTypeData: {
          series:[],
          xdata:[]
        },
        chartTypeDataPie:[
          {
            name: '速度',
            data:[
              { value: 2, name: '0-40km/h' },
              { value: 2, name: '40-60km/h' },
              { value: 2, name: '60-80km/h' },
              { value: 2, name: '80-120km/h' },
              { value: 2, name: '120km/h' }
            ]
          },
          {
            name: '类型',
            data:[
              { value: 1, name: '上行' },
              { value: 1, name: '下行' },
            ]
          }
        ]
      }
    },
    created() {
      let date = this.$moment(new Date()).format('YYYY-MM-DD')
      date = date.substr(0,10)
      this.form.date = [date,date]
      this.typeForm.date = [date,date];
      this.getData();
      this.getTypeData();
    },
    methods:{
      getData(){
        this.loading = true
        let params = {
          startTime:this.form.date[0] + ' 00:00:00',
          endTime:this.form.date[1] + ' 23:59:59'
        }
        this.$http.get("/car/carSpeedEcharts", {params:params}).then(res=>{
          if(res.success){
            if (res.data){
              this.chartData = res.data.barEchart;
              this.chartDataPie[0].name = res.data.pieEchart.series[0].name;
              this.chartDataPie[0].data = res.data.pieEchart.series[0].data;
              this.chartDataPie[1].name = res.data.pieEchart.series[1].name;
              this.chartDataPie[1].data = res.data.pieEchart.series[1].data;
            }
          } else {
            this.$message.error(res.msg)
          }
          this.loading = false
        })
      },
      handleSearch(){
        this.getData()
      },
      handleExport(){
        let params = {
          span:this.form.span,
          startTime:this.form.date[0] + ' 00:00:00',
          endTime:this.form.date[1] + ' 23:59:59'
        }
        this.$http.get('/car/exportCarSpeed', {
          contentType:"application/x-www-form-urlencoded",
          responseType: "blob",
          params: params
        }).then(res => {
          common.blobDownLoad(res)
        })
      },
      getTypeData(){
        this.loading = true
        let params = {
          startTime:this.typeForm.date[0] + ' 00:00:00',
          endTime:this.typeForm.date[1] + ' 23:59:59'
        }
        this.$http.get("/car/statistics/carType", {params:params}).then(res=>{
          if(res.success){
            if (res.data){
              this.chartTypeData = res.data.barChart;
              this.chartTypeDataPie[0].name = res.data.pieChart.series[0].name;
              this.chartTypeDataPie[0].data = res.data.pieChart.series[0].data;
              this.chartTypeDataPie[1].name = res.data.pieChart.series[1].name;
              this.chartTypeDataPie[1].data = res.data.pieChart.series[1].data;
            }
          } else {
            this.$message.error(res.msg)
          }
          this.loading = false
        })
      },
      handleTypeSearch(){
        this.getTypeData()
      },
      handleTypeExport(){

      },
    }
  }
</script>

<style scoped>
  .flow-com {
    width: 100%;
    max-height: calc(100% - 40px);
    overflow-y: auto;
    /*background: #fff;*/
  }
  .flow-four{
    width: 100%;
    height: 400px;
    margin-bottom: 15px;
    background: #fff;
  }
  .flow-four-con{
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
  }
  .flow-four-left{
    width: 65%;
    height: 100%;
  }
  .flow-four-right{
    width: 35%;
    height: 100%;
  }
</style>