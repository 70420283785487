<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    text: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let xAxisData = val.xdata;
        // let seriesData = []
        // if (val.series.length > 0){
        //   seriesData = val.series[0].data;
        // }
        let option = {
          // color: ["#3A7EEF", "#F4BB4E"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          legend: {
            show: true,
            right: "5%",
            top: "2%",
          },
          grid: {
            top: "12%",
            left: "8%",
            right: "5%",
            bottom: "6%",
            // containLabel: true
          },
          xAxis: [
            {
              type: "category",
              data: xAxisData,
              axisPointer: {
                type: "shadow",
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#C3C3C3",
                },
              },
              axisLabel: {
                color: "#5A5A5A",
                fontSize: 12,
                // rotate:45,
              },
              axisTick: {
                show: false,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "车辆总数",
              nameTextStyle: {
                color: "#5A5A5A",
                fontSize: 14,
              },
              // min: 0,
              // max: 3000,
              // interval: 50,
              axisLabel: {
                formatter: "{value}",
                color: "#5A5A5A",
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#C3C3C3",
                },
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [],
        };
        for (let item of val.series){
          option.series.push(
              {
                name: item.name,
                type: "bar",
                barWidth:12,
                tooltip: {
                  valueFormatter: function (value) {
                    return value;
                  },
                },
                data: item.data,
              }
          )
        }
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
<style scoped>
  .charts{
    width: 100%;
    height: calc(100% - 30px);
  }
</style>
